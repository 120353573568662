import React from "react"
import styled from "styled-components"
import PageHeadBanner from "../components/PageHeadBanner"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"

const RoundIcon = styled.h1`
  width: 80px;
  height: 80px;
  padding-top: 15px;
  border-radius: 50px;
  background-color: #182e40;
  display: inline-block;
  text-align: center;
  vertical-align: center;
  color: #fff;
  margin-bottom: 20px;

  &:hover {
    background-color: #ca8030;
  }
`
const ContactUs = withLayout(({ siteData, contactData }) => {
  return (
    <>
      <Seo title={"Contact Us"} siteMetadata={siteData} />
      <PageHeadBanner
        title={"Contact Details"}
        subtitle={"Inquiry and Contact Details"}
      />

      <section className={"pt-70 pb-100"}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div>
                <RoundIcon>
                  <i className="ri-map-pin-2-fill"></i>
                </RoundIcon>
                <h3 style={{ marginBottom: 40 }}>College Address</h3>
                <p>{contactData.fullAddress}</p>

                {/* <div className="gap-20"></div> */}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <RoundIcon>
                <i className="ri-mail-line"></i>
              </RoundIcon>
              <h3 style={{ marginBottom: 40 }}>Write to Us</h3>
              <p>
                <string>Email - PRINCIPAL: </string>
                <a href="#">{contactData.email}</a>
              </p>
            </div>

            <div className="col-12 col-md-4">
              <RoundIcon>
                <i className="ri-phone-fill"></i>
              </RoundIcon>
              <h3 style={{ marginBottom: 40 }}>Call Us</h3>
              <p>
                <string>Primary Contact: </string>
                <a href="#">{contactData.phone}</a> <br />
                <string>Other Contact Numbers: </string>
                <a href="#">{contactData.phone_secondary}</a>
              </p>
            </div>

            <div className="col-12 mt-4">
              <a
                href="https://sites.google.com/darwinacademymirza.com/inquiry/"
                target="_blank"
                rel="noopener noreferer"
                className="default-btn">
                Click Here to View the Contact Form
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.1894238534423!2d91.54121001478684!3d26.092734583487566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a4181f9f44a11%3A0x1a59be073f0f9f68!2sDarwin%20Academy%2C%20Mirza!5e0!3m2!1sen!2sin!4v1628429943620!5m2!1sen!2sin"
            style={{ border: 0, height: 500, width: "100%" }}
            allowFullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </>
  )
})

export default ContactUs
